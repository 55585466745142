import { useState, useEffect, useRef } from 'react';
import { Grid, Input, InputAdornment } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
// import * as clipboard from 'clipboard-polyfill/text';
import moment from 'moment';
import timeZone from "./../../../../../../../@utility/app/timeZone";

// @Utility
import { setStorage, removeStorage } from "../../../../../../../@utility/app/storage";
import alertText from "../../../../../../../@utility/app/alertText";
import validateInput from "../../../../../../../@utility/app/validateInput";

// auth
import { isUserLoggedIn } from "../../../../../../../@core/auth/utils";

//  CSS
import styles from "./../../../../assets/css/Style.module.css";
import modalStyles from "./../../../../assets/css/Modal.module.css";
import buttonStyles from "./../../../../assets/css/Button.module.css";

// Services
import {
    confirmDeposit,
    loadDepositFirst,
    cancenDeposit,
    checkDeposit
} from "../../../../../../../@core/services/depositService";
import { selectPromotionList } from "../../../../../../../@core/services/promotionService";

// Redux
// import { openAuthenApp } from "./../../../../../../../@core/redux/actions/auth/index";
// import { setDataCondition } from "./../../../../../../../@core/redux/actions/app/conditions";
import { setStatusSpinner } from "../../../../../../../@core/redux/actions/app/spinner";
import { setDepositData } from "../../../../../../../@core/redux/actions/deposit/index";
import { setDataAlert } from "../../../../../../../@core/redux/actions/app/alert";
// import { openPromotionPopup } from "./../../../../../../../@core/redux/actions/promotion/index";

// Component
// import LoadContent from "./../../../../components/LoadContent";
import SnackbarSet from "../../../../components/SnackbarSet";
import Condition from "../../component/Condition";
import BankSelect from "./../form-action/BankSelect";
import BoxResultPromptPay from "./../form-action/BoxResultPromptPay";

// Icons
// import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';

export default function FormPromptPay({ typePage, bankList, depositItems, selectBank, setSelectBank, channelInfo }) {
    const dispatch = useDispatch();
    const history = useHistory();
    const childRef = useRef();
    const [money, setMoney] = useState(0);
    const [promotionItem, setPromotionItem] = useState(null);
    const reduxPromotionPopup = useSelector(state => state.reduxPromotion);
    const [timeDepositProcess, setTimeDepositProcess] = useState(null);
    const [timeShow, setTimeShow] = useState(0);
    let snackbarJson = {};
    // const reduxAppCMS = useSelector(state => state.reduxAppCMS.cms);
    // const homeData = useSelector(state => state.reduxHome.result);
    const confirmTrasaction = () => {
        if (isUserLoggedIn()) {
            if (selectBank === 0 || selectBank === "0") {
                snackbarJson = {
                    statusShow: true,
                    statusAlert: "warning",
                    titleAlert: "แจ้งเตือน",
                    textAlert: "กรุณาเลือกบัญชีเติมเงิน! ก่อนทำรายการ"
                };

                openBox(snackbarJson);
            }
            else if (money === 0) {
                snackbarJson = {
                    statusShow: true,
                    statusAlert: "warning",
                    titleAlert: "แจ้งเตือน",
                    textAlert: "กรุณาระบุจำนวนที่ต้องการเติมเงิน !"
                };

                openBox(snackbarJson);
            }
            else {
                if (depositItems.mode !== "pay") {
                    if (money >= channelInfo?.min_balance && money <= channelInfo?.max_balance) {
                        dispatch(setStatusSpinner({ status: true, textProcess: "ระบบกำลังขอคิวอาร์โค้ดพร้อมเพย์ กรุณารอสักครู่" }));

                        let myJson = {
                            amount: money,
                            bankId: selectBank,
                            typePage: typePage,
                            channel: channelInfo.key,
                            channelId: channelInfo.id,
                            channelPlatform: channelInfo.platform
                        };

                        confirmDeposit(myJson).then((data) => {
                            const statusCode = data.code;
                            dispatch(setStatusSpinner({ status: false, textProcess: null }));
                            if (statusCode === 200 || statusCode === 0) {
                                dispatch(setDepositData(data.result));
                                setStorage("processDeposit", data.result.id);
                                setStorage("qrcodeUrl", data?.result?.pay_url);
                                setStorage("ifameMode", data?.result?.iframe_mode);
                                let firstEventTimestamp = Date.now();
                                let timeStart = firstEventTimestamp + (5 * 60 * 1000);

                                setStorage("depositPromptPayTime", timeStart);

                                history.push(`/deposit/form/${data.result.method}`);
                            }
                            // else if (statusCode === 36) {
                            //     reloadData();
                            // }
                            else {
                                if (statusCode === 2 || statusCode === 4) {
                                    snackbarJson = alertText(statusCode);
                                    openBox(snackbarJson);

                                    setTimeout(function () {
                                        window.location.href = `/`;
                                    }, 5000);
                                }
                                else {
                                    snackbarJson = alertText(statusCode);
                                    openBox(snackbarJson);
                                }
                            }
                        });
                    }
                    else {
                        if (money < channelInfo?.min_balance) {
                            snackbarJson = {
                                statusShow: true,
                                statusAlert: "warning",
                                titleAlert: "แจ้งเตือน",
                                textAlert: `ยอดเติมเงินเริ่มต้นที่ ${channelInfo?.min_balance} บาท`
                            };

                            openBox(snackbarJson);
                        }

                        if (money > channelInfo?.max_balance) {
                            snackbarJson = {
                                statusShow: true,
                                statusAlert: "warning",
                                titleAlert: "แจ้งเตือน",
                                textAlert: `ยอดเติมเงินสูงสุด ${channelInfo?.max_balance} บาท`
                            };

                            openBox(snackbarJson);
                        }
                    }
                }
            }
        }
    };

    const openBox = (snackbarJson) => {
        dispatch(setDataAlert({
            type: snackbarJson.statusAlert,
            title: snackbarJson.titleAlert,
            text: snackbarJson.textAlert,
            action: true,
            redirect: null,
            show: true,
            buttonText: "ตกลง"
        }));
    };

    const reloadData = (page) => {
        loadDepositFirst().then((data) => {
            const statusCode = data.code;

            if (statusCode === 200 || statusCode === 0) {
                dispatch(setDepositData(data.result));
                setStorage("processDeposit", data.result.id);

                if (page === "home") {
                    history.push("/");
                }
                else {
                    history.push("/deposit/pending/manual");
                }
            }
            else {
                if (statusCode === 2 || statusCode === 4) {
                    snackbarJson = alertText(statusCode);
                    childRef.current.snackbar(snackbarJson);

                    setTimeout(function () {
                        window.location.href = `/`;
                    }, 5000);
                }
                else {
                    snackbarJson = alertText(statusCode);
                    childRef.current.snackbar(snackbarJson);
                }
            }
        });
    };

    const cancelTransaction = () => {
        dispatch(setStatusSpinner({ status: true, textProcess: null }));

        cancenDeposit().then((data) => {
            const statusCode = data.code;
            dispatch(setStatusSpinner({ status: false, textProcess: null }));

            if (statusCode === 200 || statusCode === 0) {
                snackbarJson = {
                    statusShow: true,
                    statusAlert: "success",
                    titleAlert: "แจ้งเตือน",
                    textAlert: "ยกเลิกรายการเติมเงินสำเร็จ !"
                };

                removeStorage("qrcodeUrl");
                removeStorage("processDeposit");
                removeStorage("depositPromptPayTime");
                openBox(snackbarJson);
                reloadData("home");
            }
            else {
                alertWarning(statusCode);
            }
        });
    };

    const alertWarning = (statusCode) => {
        if (statusCode === 2 || statusCode === 4) {
            snackbarJson = alertText(statusCode);
            openBox(snackbarJson);

            setTimeout(function () {
                window.location.href = `/`;
            }, 5000);
        }
        else {
            snackbarJson = alertText(statusCode);
            openBox(snackbarJson);
        }
    };

    const loadPromotionData = (amount) => {
        selectPromotionList(amount).then((data) => {
            const statusCode = data.code;

            if (statusCode === 200 || statusCode === 0) {
                setPromotionItem(data.result === null ? [] : data.result);
            }
            else {
                setPromotionItem([]);
            }
        });
    };

    const resetNumber = () => {
        if (money === 0) {
            setMoney("");
        }
    };

    const checkTransaction = (checkTime) => {
        checkDeposit(depositItems.id).then((data) => {
            const statusCode = data.code;

            if (statusCode === 200 || statusCode === 0) {
                switch (data.result.status) {
                    case 0:
                    case 1: break;
                    case 2:
                        snackbarJson = {
                            statusShow: true,
                            statusAlert: "success",
                            titleAlert: "แจ้งเตือน",
                            textAlert: "ทำรายการเติมเงินสำเร็จ !"
                        };

                        openBox(snackbarJson);

                        clearInterval(checkTime);
                        removeStorage("processDeposit");
                        reloadData("home");
                        break;
                    case 3:
                        snackbarJson = {
                            statusShow: true,
                            statusAlert: "error",
                            titleAlert: "แจ้งเตือน",
                            textAlert: "ทำรายการเติมเงินไม่สำเร็จ ! กรุณาติดต่อผู้ดูแลระบบ"
                        };

                        openBox(snackbarJson);

                        clearInterval(checkTime);
                        removeStorage("processDeposit");
                        reloadData("/");
                        break;
                    default:
                        clearInterval(checkTime);
                        removeStorage("processDeposit");
                        break;
                }
            }
            else {
                if (statusCode === 2 || statusCode === 4) {
                    snackbarJson = alertText(statusCode);
                    openBox(snackbarJson);

                    setTimeout(function () {
                        window.location.href = `/`;
                    }, 5000);
                }
                else {
                    snackbarJson = alertText(statusCode);
                    openBox(snackbarJson);
                }
            }
        });
    };

    useEffect(() => {
        if (isUserLoggedIn()) {
            if (depositItems !== null) {
                if (depositItems.mode === "pay") {
                    let amountData = parseInt(depositItems.amount);
                    setMoney(amountData);
                    loadPromotionData(amountData);
                }
            }
        }
    }, [depositItems]);

    useEffect(() => {
        if (depositItems !== null) {
            if (depositItems.mode === "pay") {
                setTimeDepositProcess(depositItems.time_create);

                let timer = depositItems.time, minutes, seconds;
                let countTime = 0;
                const checkTime = setInterval(() => {
                    minutes = parseInt(timer / 60, 10);
                    seconds = parseInt(timer % 60, 10);

                    minutes = minutes < 10 ? "0" + minutes : minutes;
                    seconds = seconds < 10 ? "0" + seconds : seconds;

                    // setTimeShow(`${minutes} : ${seconds}`);
                    if (--timer < 0) {
                        // setTimeShow(0);
                        clearInterval(checkTime);
                        return null;
                    }
                    else {
                        countTime++;
                        if (countTime === 3) {
                            checkTransaction(checkTime);
                            countTime = 0;
                        }
                    }

                }, 1000);

                return () => {
                    clearInterval(checkTime);
                }
            } else {
                setTimeDepositProcess(null);
            }
        }
    }, [depositItems]);

    const secondsToTime = (secs) => {
        let divisor_for_minutes = secs % (60 * 60);
        let minutes = Math.floor(divisor_for_minutes / 60);

        let divisor_for_seconds = divisor_for_minutes % 60;
        let seconds = Math.ceil(divisor_for_seconds);

        minutes = minutes < 10 ? "0" + minutes : minutes;
        seconds = seconds < 10 ? "0" + seconds : seconds;

        setTimeShow(`${minutes} : ${seconds}`);
    };

    useEffect(() => {
        if (timeDepositProcess !== null) {
            let travelTime = moment(timeDepositProcess).add(15, 'minutes');
            let dateEnd = timeZone(travelTime, "noFormat");
            let dateCurrent = timeZone(moment(), "noFormat");
            let duration = moment.duration(dateEnd.diff(dateCurrent));
            let seconds = duration.asSeconds();

            const checkTime = setInterval(() => {
                --seconds;
                if (seconds <= 0) {
                    clearInterval(checkTime);
                    setTimeDepositProcess(null);
                    setTimeShow(0);
                }
                else {
                    secondsToTime(seconds);
                }
            }, 1000);

            return () => {
                clearInterval(seconds);
            }
        }
    }, [timeDepositProcess]);

    const renderCancelBtn = () => {
        if (depositItems.mode !== "pay") return null;

        if (timeDepositProcess !== null) {
            return (
                <div className={styles.boxMarginFormTop}>
                    <div className="center">
                        <button className={[buttonStyles.btnConfirm, buttonStyles.btnCancel].join(" ")} style={{ opacity: "0.6" }}>
                            ยกเลิกการโอน ({timeShow})
                        </button>
                    </div>
                </div>
            );
        }

        return (
            <div className={styles.boxMarginFormTop}>
            <div className="center">
                <button className={[buttonStyles.btnConfirm, buttonStyles.btnCancel].join(" ")} onClick={cancelTransaction}>
                    ยกเลิกการโอน
                </button>
            </div>
        </div>
        );
    };

    return (
        <>
            <SnackbarSet ref={childRef} />

            <div className={modalStyles.boxTitle}>
                {
                    channelInfo && (
                        channelInfo?.name
                    )
                }
            </div>

            {
                depositItems !== null && (
                    <BankSelect isUserLoggedIn={isUserLoggedIn()} bankList={bankList} depositItems={depositItems} typePage={typePage} selectBank={selectBank} setSelectBank={setSelectBank}></BankSelect>
                )
            }

            {
                depositItems !== null && (
                    depositItems.mode === "deposit" && (
                        channelInfo && (
                            bankList !== null && bankList.length > 0 && (
                                <div id="DepositInput" className={[styles.inputBorder, styles.boxMarginMediumTop].join(" ")}>
                                    <div className={styles.textBankMoney} style={{ color: "white" }}>
                                        จำนวนเงิน :
                                    </div>
                                    <Grid container alignItems="center" style={{ marginTop: "-8px" }} spacing={1}>
                                        <Grid item xs={8} md={9}>
                                            <div className={[styles.inputMoneyMargin, "center"].join(" ")}>
                                                <Input
                                                    id="money"
                                                    variant="standard"
                                                    fullWidth
                                                    type="number"
                                                    pattern="[0-9]*"
                                                    color="secondary"
                                                    className={[styles.bankTitile, styles.yellow, styles.inputAdornment, styles.transactionInput].join(" ")}
                                                    placeholder="ระบุจำนวนเงิน"
                                                    value={money}
                                                    style={{ borderBottom: "1px solid white" }}
                                                    onKeyPress={(event) => validateInput(event, "numberDecimal")}
                                                    onChange={(e) => { setMoney(e.target.value) }}
                                                    onClick={() => { resetNumber() }}
                                                    endAdornment={<InputAdornment position="end"><div className={[styles.bankTitile, styles.white, styles.inputAdornment].join(" ")} >บาท</div></InputAdornment>}
                                                ></Input>
                                            </div>
                                        </Grid>
                                        <Grid item xs={4} md={3}>
                                            <div className={buttonStyles.btnReqBank} onClick={confirmTrasaction}>
                                                ขอ{channelInfo?.platform === "bank" ? "เลขบัญชี" : "คิวอาร์โค้ด"}
                                            </div>
                                        </Grid>
                                    </Grid>
                                    <div className={styles.textBankMoney} style={{ marginTop: "4px", fontSize: "14px" }}>
                                        ยอดเริ่มต้น {channelInfo?.min_balance} สูงสุด {channelInfo?.max_balance} บาท
                                    </div>
                                </div>
                            )
                        )

                    )
                )
            }

            <div className={styles.boxMarginFormTop}>
                {
                    depositItems !== null && (
                        depositItems.mode === "pay" ?
                            <BoxResultPromptPay
                                depositItems={depositItems}
                                openBox={openBox}
                                promotionItem={promotionItem}
                                reduxPromotionPopup={reduxPromotionPopup}
                                history={history}
                            ></BoxResultPromptPay>
                            :
                            <div className={styles.boxBankAgentBorder}>
                                <Condition typePage={typePage} condition={channelInfo?.condition}></Condition>
                            </div>
                    )
                }
            </div>


            {
                depositItems !== null && (
                    depositItems.mode === "pay" && (
                        <div>
                            {renderCancelBtn()}
                        </div>
                        // <div className={styles.boxMarginFormTop}>
                        //     <div className="center">
                        //         <button className={[buttonStyles.btnConfirm, buttonStyles.btnCancel].join(" ")} onClick={cancelTransaction}>
                        //             ยกเลิกการโอน
                        //         </button>
                        //     </div>
                        // </div>
                    )
                )
            }
        </>
    );
}