import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid } from '@material-ui/core';

// Redux
import { openPromotionPopup } from "../../../../../../../@core/redux/actions/promotion/index";
import { setDataContact } from "../../../../../../../@core/redux/actions/app/contact";

// Services
import { updatePromotionAuto } from "./../../../../../../../@core/services/promotionService";

// @Utility
import { getStorage, setStorage, removeStorage } from "../../../../../../../@utility/app/storage";
import currencyFormat from "../../../../../../../@utility/numbers/currencyFormat";

// CSS
import styles from "./../../../../assets/css/Style.module.css";
import buttonStyles from "./../../../../assets/css/Button.module.css";

// Component
import Switch from "../../component/Switch";

export default function BoxResultPromptPay({ depositItems, reduxPromotionPopup, promotionItem }) {
    const dispatch = useDispatch();
    const [promotionSwitch, setPromotionSwitch] = useState(false);
    const homeData = useSelector(state => state.reduxHome.result);
    const [urlIframe, setUrlIframe] = useState("");
    const [iframeLoad, setIframeLoad] = useState("");
    const [dataTime, setDataTime] = useState(null);
    const [timeShow, setTimeShow] = useState(0);
    const [showContact, setShowContact] = useState(false);
    let checkTime = null;

    const openPromotion = (data) => {
        dispatch(openPromotionPopup({
            tab: "promotionPopup",
            show: true,
            promotionId: data.id,
            promotionData: data
        }));
    };

    const explodeDecimal = (decimal) => {
        let decimalSplit = decimal.split(".");

        return <>
            {decimalSplit[0]}.<span style={{ color: "#FFE600" }}>{decimalSplit[1]}</span>
        </>
    };

    const newTabUrlAkPay = () => {
        const depositPromptPayTime = getStorage("depositPromptPayTime");

        if (!depositPromptPayTime) {
            let firstEventTimestamp = Date.now();
            let timeStart = firstEventTimestamp + (5 * 60 * 1000);
            setStorage("depositPromptPayTime", timeStart);
        }

        window.location.href = urlIframe;
    };

    const openContact = () => {
        dispatch(setDataContact(true));
    };

    const secondsToTime = (secs) => {
        let divisor_for_minutes = secs % (60 * 60);
        let minutes = Math.floor(divisor_for_minutes / 60);

        let divisor_for_seconds = divisor_for_minutes % 60;
        let seconds = Math.ceil(divisor_for_seconds);

        minutes = minutes < 10 ? "0" + minutes : minutes;
        seconds = seconds < 10 ? "0" + seconds : seconds;

        setTimeShow(`${minutes} : ${seconds}`);
    };

    useEffect(() => {
        if (depositItems) {
            setUrlIframe(getStorage("qrcodeUrl"));
            console.log(getStorage("ifameMode"))
            setIframeLoad(getStorage("ifameMode"));

            const depositPromptPayTime = getStorage("depositPromptPayTime");

            if (depositPromptPayTime) {
                setDataTime(Number(depositPromptPayTime));
            }

            if (!depositPromptPayTime) {
                setShowContact(true);
                removeStorage("depositPromptPayTime");
                if (checkTime) {
                    clearInterval(checkTime);
                }
            }
        }
    }, [depositItems]);

    useEffect(() => {
        if (homeData !== null) {
            setPromotionSwitch(homeData.promotion_auto === 1 ? true : false);
        }
    }, [homeData]);

    useEffect(() => {
        if (promotionSwitch !== null) {
            let promotion_auto = promotionSwitch ? 1 : 0;
            updatePromotionAuto(promotion_auto).then(() => { });
        }
    }, [promotionSwitch]);

    // useEffect(() => {
    //     if (promotionSwitch === false) {
    //         dispatch(openPromotionPopup({
    //             tab: "promotionPopup",
    //             show: false,
    //             promotionId: 0,
    //             promotionSelect: false,
    //             promotionData: null
    //         }));
    //     }
    //     else {
    //         if (promotionItem.length === 1) {
    //             dispatch(openPromotionPopup({
    //                 tab: "promotionPopup",
    //                 show: false,
    //                 promotionSelect: true,
    //                 promotionId: promotionItem[0].id,
    //                 promotionData: promotionItem[0]
    //             }));
    //         }
    //     }
    // }, [promotionSwitch]);

    useEffect(() => {
        if (dataTime && dataTime != 0) {
            let timeStart = dataTime;
            let timeNow = Date.now();
            let seconds = (timeStart - timeNow) / 1000;
            if (seconds > 0) {
                checkTime = setInterval(() => {
                    --seconds;
                    const qrcodeUrl = getStorage("qrcodeUrl");

                    if (qrcodeUrl) {
                        if (seconds <= 0) {
                            clearInterval(checkTime);
                            setTimeShow(0);
                            setShowContact(true);
                            removeStorage("depositPromptPayTime");
                        }
                        else {
                            secondsToTime(seconds);
                        }
                    }
                    else {
                        clearInterval(checkTime);
                        setTimeShow(0);
                        setShowContact(true);
                        removeStorage("depositPromptPayTime");
                    }
                }, 1000);
            }
            else {
                clearInterval(checkTime);
                setTimeShow(0);
                setShowContact(true);
                removeStorage("depositPromptPayTime");
            }
        }

        if (!dataTime) {
            if (checkTime) {
                clearInterval(checkTime);
            }
        }
    }, [dataTime])

    return (
        (urlIframe !== undefined && urlIframe !== null) && (
            <>
                {
                    iframeLoad === "0" ?
                        <>
                            <div className={styles.boxDetailTop} style={{ borderBottom: "2px dashed #000000" }}>
                                <div className={styles.boxAccountAgentTitle}>
                                    จำนวนเงินที่ต้องโอน
                                </div>

                                <div className={styles.boxMarginTop}>
                                    <Grid container alignItems="center" spacing={2}>
                                        <Grid item xs={5}>
                                            ยอดทั้งหมด
                                        </Grid>
                                        <Grid item xs={5}>
                                            <div className={`${styles.boxDetailAmountNumber} ${styles.boxDetailAmountNumberText}`}>
                                                {
                                                    explodeDecimal(currencyFormat(depositItems?.amount, 2))
                                                }
                                            </div>
                                        </Grid>
                                        <Grid item xs={2}>
                                            บาท
                                        </Grid>
                                    </Grid>
                                </div>

                                <div className={[styles.boxMarginTop, "center"].join(" ")}>
                                    <div className={styles.bankPromotionComment}>
                                        ** กรุณาโอนเงินจำนวน <span style={{ fontWeight: "bold", color: "black" }}>{currencyFormat(depositItems?.amount, 2)}</span> บาท ภายใน 5 นาที **
                                    </div>
                                </div>
                            </div>
                            <div className={styles.boxDetailTop}>
                                <div className="center">
                                    {
                                        showContact ?
                                            <button className={[buttonStyles.btnRedirect, buttonStyles.btnConfirm].join(" ")} onClick={openContact}>
                                                ติดต่อแอดมิน
                                            </button>
                                            :
                                            <button className={[buttonStyles.btnRedirect, buttonStyles.btnConfirm].join(" ")} onClick={() => newTabUrlAkPay()}>
                                                ดูบัญชีโอนเงิน {timeShow != 0 ? `(${timeShow})` : ""}
                                            </button>
                                    }

                                </div>
                                <div className={[styles.boxMarginTop, "center"].join(" ")}>
                                    {
                                        showContact ?
                                            <div className={styles.bankPromotionComment}>
                                                ** หมดเวลาทำรายการ <span style={{ fontWeight: "bold", color: "black" }}>"กรุณาติดต่อแอดมิน"</span> เพื่อดำเนินการ **
                                            </div>
                                            :
                                            <div className={styles.bankPromotionComment}>
                                                ** กรุณากดที่ปุ่ม  <span style={{ fontWeight: "bold", color: "black" }}>"ดูบัญชีโอนเงิน"</span> เพื่อโอนเงินเข้าระบบ **
                                            </div>
                                    }

                                </div>
                            </div>
                        </>
                        :
                        <div className={styles.boxDetailTop}>
                            <iframe
                                title="iframe"
                                className={styles.qrCodePromptPay}
                                src={urlIframe}
                                scrolling="no"
                                frameBorder="0"
                            />
                        </div>
                }

                <div className={styles.boxMarginTop}>
                    <div className={styles.boxDetailTop}>
                        <Grid container alignItems="center" justifyContent="space-between" spacing={2}>
                            <Grid item>
                                <div className={styles.boxAccountAgentTitle}>
                                    เลือกรับโปรโมชั่นอัตโนมัติ
                                </div>
                            </Grid>
                            <Grid item>
                                <Switch
                                    isOn={promotionSwitch}
                                    onColor="#38C172"
                                    handleToggle={() => setPromotionSwitch(!promotionSwitch)}
                                />
                            </Grid>
                        </Grid>
                    </div>
                </div>
            </>
        )
    );
}